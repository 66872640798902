.quiz_navbar {
  /* height: 5%; */
  width: 100%;
  padding: 0% 5%;
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
}

.backBtn {
  cursor: pointer;
  font-size: 34px;
  color: #5B1674;
}

.quizPageBody{
  /* background: url('../../assets/img/mainBg.png'); */
  background: #c5c5c5;
  /* background: url('../../assets/img/quizBg.png'); */
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  font-family: anekBangla;
  background-position: bottom;
}