


/* src/components/Referral/ReferralEarning.module.css */
.referralContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 8px 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 0px 16px;
  }
  
  .referralItem {
    display: flex;
    align-items: center;
  }
  
  .icon {
    font-size: 24px;
    margin-right: 10px;
    color: #4a148c;
  }
  
  .text {
    text-align: left;
  }
  
  .label {
    font-size: 14px;
    color: #4a148c;
  }
  
  .value {
    font-size: 18px;
    font-weight: bold;
    color: #4a148c;
    text-align: center;
  }
  
  .divider {
    width: 1px;
    height: 40px;
    background-color: #ddd;
    margin: 0 20px;
  }