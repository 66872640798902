.btn-close{
  color: aqua;
}

.modal-header {
  background: #fff;
  border-bottom: 1px solid #ededed;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  color: #232323;
  text-align: center;
}

.modalContent {
  border-radius: 16px;
  width: 90%;
  margin: 0 auto;
  background: #ffffff;
  border: 2px solid #cccccc99;
  overflow: hidden;
}

.modal-title {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  width: 100%;
}
.modal{
  z-index: 999999 !important;
}
.modal-dialog {
  max-width: 425px;
  z-index: 999999;
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #5B1674;
  max-height: 65dvh;
  overflow: auto;
}

.modalButtonIcon{
  border: 1px solid var(--darkerAccentColor);
  font-size: 18px;
  color: var(--mainAccentColor);
}
