.bannerSwiperSlide {
  width: 80% !important;
  /* height: 90% !important; */
}

.quizCard2 {
  width: 90%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* margin: 0 auto; */
  /* gap: 2vh; */
  margin: 0 auto;
  margin-top: 3dvh;
}

/* .banner-avatarImg{
  width: 18%;
  border-radius: 100%;
  position: absolute;
  top: 8px;
  left: 5%;
  height: 70%;
  object-fit: cover;
} */

.rewardBottomSwiper {
  width: 90%;
  height: 40dvh;
  margin: 0 auto;
  bottom: 10dvh;
  position: absolute;
  margin-left: 5%;
}
.rewardBottomSwiperMain{
  /* width: 100%; */
  width: 100%;
  height: 100%;
  display: flex !important;
  align-items: center;
}
.rewardBannerSwiperSlide{
  background-position: center;
  background-size: cover;
  width: 65% !important;
  height: 90% !important;
}
.rewardBannerSwiperSlide img{
  display: block;
  /* width: 100%; */
  margin: 0 auto;
  height: 100%;
}
@media (min-height: 650px) {
  .rewardBottomSwiper {
    height: 42dvh;
  }
}
@media (min-height: 700px) {
  .rewardBottomSwiper {
    height: 45dvh;
  }
  .rewardBannerSwiperSlide img{
    height: 85%;
  }
}
@media (max-height: 700px) {
  .bannerDiv {
    max-height: 80dvh;
  }
}
@media (min-height: 700px) {
  .bannerDiv {
    max-height: 80dvh;
  }
}
.bannerDiv{
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  /* margin-top: 1em; */
  /* gap: 1em; */
  max-height: 80dvh;
  overflow-y: auto;
  padding: 3%;
}

.singleBanner{
  /* overflow: hidden; */
  border-radius: 10px;
  filter: drop-shadow(0px 4px 5px #000000bb);
}

.mixupText {
  font-size: xxx-large;
  text-align: center;
  font-weight: 600;
  color: #ff0000;
}

.mixupPlayBtn {
  text-align: center;
  margin: 0 auto;
  width: 30%;
  padding: 10px;
  background-color: #f00;
  color: #fff;
  border-radius: 10%;
  font-weight: 600;
  font-size: x-large;
  cursor: pointer;
}

.mixupLogoImg{
  width: 70%;
  margin: 0dvh auto -2dvh auto;
}
.mixupPlayBtnImg{
  width: 100%;
  margin: 0 auto;
  aspect-ratio: 1094/876;
  position: relative;
}

.mixupBanglaImg{
  position: absolute;
  top: 56%;
  left: 35%;
  width: 38%;
}
.mixupEnglishImg{
  position: absolute;
  top: 32%;
  left: 35%;
  width: 38%;
}