/* PaymentHistory.module.css */
.paymentHistory {
  /* padding: 20px; */
  /* background-color: #f9f9f9; */
}
.title{
  font-size: 20px;
  font-weight: 700;
  color: #232323;
  margin-bottom: 16px;
  font-family: 'LiAdorNoirrit', sans-serif;
}
.historyList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.historyItem {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 1px 4px 0px #00000026;

}

.payment-info h3 {
  color: #3b5998; /* Example color */
  margin: 0;
  font-size: 1.2em;
}

.payment-info p {
  color: #666;
  margin: 0;
}

.payment-amount p {
  color: #333;
  font-weight: bold;
  margin: 0;
}

.paymentDuration {
  color: #999;
  font-size: 0.9em;
}

.subsType{
  font-size: 16px;
  font-weight: 600;
  color: #320C40;
}