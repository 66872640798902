.contactVis {
  width: 35%;
}
.socialIconBox {
  font-size: xx-large;
}
.modalSubsCustom{
  font-size: 18px;
  text-align: center;
  font-weight: 600;
}

.tcModal {
  /* padding: 28px 0px 32px 0px; */
  }
  
  .tcModalHeader {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 0px 16px;
    color: #232323;
    background: linear-gradient(161.15deg, #DAEAD9 12.73%, #C4E0C6 72.95%);
    border-bottom: 1px solid #DEE2E6;
  }
  
  .tcModalIcon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  
  .tcModalTitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #212529;
  }
  
  .tcModalContent {
    padding: 8px 16px 32px 16px;
  }
  
  .accordion {
    margin-top: 16px;
    width: 94%;
  }
  
  .accordionItem {
    border: none;
    background: #FFFFFF !important;
    border-radius: 8px !important;
    overflow: hidden;
    margin-bottom: 0.5rem;
    border: 1px solid var(--Stroke-1, #EDEDED)
  }
  
  .accordionButton {
    /* padding: 12px 16px; */
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #212529;
  }
  /* Remove default focus outline */
  .accordionButton button:focus {
    box-shadow: none;
    border-color: rgba(0,0,0,.125);
  }
  .accordionBody {
    padding: 16px;
    font-size: 14px;
    line-height: 20px;
    color: #495057;
  }
  
  