.contactVis {
  width: 35%;
}
.socialIconBox {
  font-size: xx-large;
}
.loadingSpinner{
  /* height: ; */
  color: var(--darkerAccentColor);
  width: 2.5em;
  height: 2.5em;
}