.contactContainer {
  border-radius: 12px;
  padding: 16px 16px 32px 16px;
  max-width: 500px;
  margin: 0 auto;
}

.contactHeader {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
  flex-direction: column;
}

.contactModalIcon {
  width: 60px;
  height: 60px;
}

.contactModalTitle {
  font-size: 20px;
  font-weight: 600;
  color: #333;
}

.faqList {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.faqItem {
  border-radius: 8px;
  padding: 16px;
  background: #fff;
  border: 1px solid var(--Stroke-1, #EDEDED);
}

.question {
  color: #232323;
  font-weight: 600;
  margin-bottom: 8px;
}

.answer {
  color: #535353;
  font-weight: 400;
}

.answer a {
  color: #007bff;
  text-decoration: none;
}
.socialIcons{
  font-size: 32px;
  display: flex;
  gap: 16px;
  align-items: center;
  color: #007bff;
}
.answer a:hover {
  text-decoration: underline;
}

/* For social media icons */
.socialIcons {
  display: flex;
  gap: 16px;
}

.socialIcons a {
  color: #007bff;
  font-size: 32px;
}

.socialIcons a:hover {
  color: #007bff;
}

/* For unsubscribe button */
.unsubscribeBtn {
  background: #dc3545;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.unsubscribeBtn:hover {
  background: #c82333;
}

.unsubscribe-button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.unsubscribe-btn {
  background-color: #ff4d4f;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.unsubscribe-btn:hover {
  background-color: #ff7875;
}

.unsubscribe-btn:active {
  background-color: #d9363e;
}