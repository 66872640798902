.landingPage{
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.landingImg{
  width: 100%;
  height: 100%;
}

.tcButton {
  padding: 10px 15px;
  color: #f5f5f5;
  background-color: #6c4e9d;
  border: 1px solid #ccc;
  border-radius: 10px;
  z-index: 1;
  width: fit-content;
  cursor: pointer;
  left: 50%;
  top: 80%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.tcButton:hover {
  background-color: #f0f0f0;
  color: #6c4e9d;
}