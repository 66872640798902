.redeemButton{
  width: 30%;
  border-radius: 20px;
  background: var(--mainAccentColor);
  color: white;
  margin: 0 auto;
}

.inputBox{
  width: 100%;
  border-radius: 7px;
  border: none;
  text-align: center;
  
}

.inputBox:focus{
  outline: none;
}

.inputBox{
  -webkit-user-select: text;
}