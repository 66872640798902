.xImg {
    width: 20px;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    z-index: 9;
}

.gameSelectionModalBody {
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    color: #232323;
    padding: 24px 16px 24px 16px;
}

.gameSelectionModalDialog {
    padding: 16px;
}

.gameSelectionModalContent {
    border-radius: 16px;

}
.logo {
    width: 50%;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
}

.infoText {
    font-family: 'Noto Sans Bengali';
    font-weight: 700;
    font-size: 20px;
    line-height: 26.5px;
    letter-spacing: 0px;
    text-align: center;    
    display: inline-block;
    width: 80%;
}

.gameSelectionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 100%;
}

.gameSelectionItem {
    font-family: 'Noto Sans Bengali';
    font-weight: 600;
    font-size: 18px;
    border: 1px solid #C1B8D9;
    border-radius: 28px;
    width: 100%;
    padding: 8px 16px;
    background-color: #EBE8F3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.arrowImg {
    width: 24px;
    aspect-ratio: 1/1;
}