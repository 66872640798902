.container {
  min-height: 100vh;
  background-color: #320C40;
  display: flex;
  flex-direction: column;
}

/* Top Section - 360px */
.topSection {
  position: relative;
  padding: 0px 16px;
  height: 360px;
  width: 100%;
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}

.mainNav {
  width: 100%;
  height: 40px;
  position: relative;
  top: 24px;
  margin: 0 auto;
  padding: 4px 4px 4px 4px;
  border-radius: 8px;
  display: flex;
  box-shadow: 0px 0px 2px 0px #00000026;
  background: #14001B;
  left: 50%;
  transform: translateX(-50%);
}

.mainNavButton {
  border: none;
  background: transparent;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainNavActive {
  background: #ffffff26;
  border: none;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.topPlayers {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: calc(100% - 32px);
  bottom: 0;
  background: #ffffff0d;
  height: 108px;
  border-radius: 12px 12px 0px 0px;
}

.playerCard1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  width: 30%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 37%;
  height: 152px;
  z-index: 2;
  transform: translateX(-50%);
  border-radius: 24px 24px 0px 0px;
  background: #462453;
}
.playerCard2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  width: 31.5%;
  height: 100%;
  position: absolute;
  left: 0;
}

.avatarWrapper {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.dataWrapper {
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;
  z-index: 1;
}

.playerCard3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  width: 31.5%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
}

.avatarContainer {
  position: relative;
  background: rgba(108, 78, 217, 0.3);
  border-radius: 50%;
  padding: 3px;
  top: -35%;
}

.avatar1 {
  width: 78px;
  height: 78px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ffaa00;
}
.avatar2 {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #00d95f;
}
.avatar3 {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #009bd6;
}

.firstPlace .avatarContainer {
  background: rgba(255, 215, 0, 0.2);
}

.firstPlace .avatar {
  width: 85px;
  height: 85px;
  border: 3px solid #ffd700;
}

.crown {
  position: absolute;
  top: -22px;
  left: 50%;
  transform: translateX(-50%);
  width: 32px;
  height: auto;
  z-index: 3;
}

.msisdn {
  font-size: 13px;
  color: white;
  margin: 8px 0 2px;
  text-align: center;
}

.scoreInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1px;
}

.scoreLabel {
  font-size: 12px;
  color: #fff;
}

.scoreValue {
  font-size: 14px;
  font-weight: 500;
  color: white;
}

.timeValue {
  font-size: 10px;
  color: #fff;
  margin-top: 1px;
}

/* Add glow effect for winners */
.avatarContainer::after {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  border-radius: 50%;
  background: radial-gradient(
    circle at center,
    rgba(108, 78, 217, 0.2),
    transparent
  );
  z-index: -1;
}

.firstPlace .avatarContainer::after {
  background: radial-gradient(
    circle at center,
    rgba(255, 215, 0, 0.2),
    transparent
  );
}

/* Middle Section - Current User - 52px */
.currentUserSection {
  height: 52px;
  border-bottom: 1px solid #eee;
  width: 100%;
  /* flex: 1 1; */
  background-color: white;
  overflow-y: auto;
  padding: 2px 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* Bottom Section - Other Users - Remaining Height */
.otherUsersSection {
  flex: 1;
  background-color: white;
  overflow-y: auto;
  padding: 16px 16px 32px 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* Shared styles for user rows */
.userRow {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  gap: 12px;
  box-shadow: 0px 0px 3px 0px #00000033;
  border-radius: 6px;
}

.rank {
  width: 40px;
  color: #232323;
}

.userAvatar {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  object-fit: cover;
}

.userInfo {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.msisdn {
  font-size: 12px;
  font-weight: 500;
  color: #232323;
}
.msisdn {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}
.userMsisdn {
  width: 88px;
  font-size: 14px;
  font-weight: 500;
  color: #232323;
}

.timeAndScore {
  display: flex;
  gap: 32px;
  color: #232323;
  font-size: 14px;
  font-weight: 500;
  width: 80px;
  justify-content: space-around;
}

.subNav {
  position: relative;
  top: 44px;
  display: flex;
  padding: 0 0px 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  justify-content: space-between;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
}
.subNavWinner {
  position: relative;
  top: 44px;
  display: flex;
  padding: 0 0px 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  justify-content: end;
  align-items: center;
}

.subNavButton {
  background: none;
  border: none;
  color: #ffffff;
  opacity: 0.7;
  padding: 8px 0;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  flex: 1;
  font-weight: 500;
}

.subNavActive {
  flex: 1;
  opacity: 1;
  color: #ffd700;
}

.subNavActive::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #e3b100;
}

.dropdown {
  appearance: none;
  background-color: #ffffff1a;
  /* border: 1px solid #ccc; */
  border-radius: 30px;
  padding: 5px 20px 5px 10px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  max-width: 100px;
}

.dropdown:focus {
  outline: none; /* Remove focus outline */
  border: none;
}
.dropdown option {
  padding: 8px;
  font-size: 16px;
  background-color: #fff;
  color: #333;
}
.subNavWinner::after {
  content: "▼"; /* Arrow character */
  position: absolute;
  right: 14px; /* Position from the right */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Adjust for vertical centering */
  pointer-events: none; /* Allow clicks through the arrow */
  color: #fff; /* Arrow color */
  font-size: 14px;
}

.crownNumber {
  position: absolute;
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%) rotate(-45deg);
  width: 18px;
  height: 18px;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffaa00;
  border-radius: 3px;
}

.crownNumber::before {
  content: "1";
  transform: rotate(45deg); /* Keep the text straight */
  display: block;
}
.crownNumber2 {
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%) rotate(-45deg);
  width: 16px;
  height: 16px;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00d95f;
  border-radius: 3px;
}

.crownNumber2::before {
  content: "2";
  transform: rotate(45deg); /* Keep the text straight */
  display: block;
}
.crownNumber3 {
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%) rotate(-45deg);
  width: 16px;
  height: 16px;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #009bd6;
  border-radius: 3px;
}

.crownNumber3::before {
  content: "3";
  transform: rotate(45deg); /* Keep the text straight */
  display: block;
}

/* Style the dropdown options when open */
.dropdown option {
  background-color: #3D2974;
  color: #fff;
  padding: 8px;
  border-radius: 8px;
}

/* For some browsers, you might need this additional selector */


/* For styling the selected option */


/* For styling option on hover */
.dropdown option:hover {
  background-color: cyan; /* lighter tomato */
}

/* Style the dropdown list box when open */
.dropdown {
  /* ... existing styles ... */
  option {
    background-color: #3D2974;
    color: #fff;
    padding: 8px;
    border-radius: 8px;
  }
}

/* Style the select element when opened */
.dropdown[data-opened="true"] {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid #eee;
}

/* Style the listbox that contains the options */
.dropdown::-webkit-listbox {
  background-color: whitesmoke;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

/* For Firefox */
.dropdown menupopup {
  background-color: whitesmoke;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

/* For IE/Edge */
.dropdown::-ms-expand {
  background-color: whitesmoke;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
