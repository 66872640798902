.homePageBody{
  /* background: url('../../assets/img/bg_min_v2.png'); */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  font-family: anekBangla;
}

.header-img-div {
display: flex;
justify-content: space-around;
width: 82%;
height: 12dvh;
margin-top: 0dvh;
margin: 0 auto;
position: relative;
background: #fff;
/* background: url(../../assets/img/v2/playerCardPrev_white.png); */
border-radius: 20px;
align-items: center;
filter: drop-shadow(-1px 3px 6px #6d6767bb);
}

.header-img {
margin: 0 auto;
width: 100%;
}

.headerDailyScore {
position: absolute;
bottom: 20%;
left: 30%;
width: 60px;
color: var(--darkerAccentColor);
font-weight: bolder;
text-align: center;
}

.headerEventScore {
position: absolute;
bottom: 20%;
right: 16%;
width: 66px;
color: var(--darkerAccentColor);
font-weight: bolder;
text-align: center;
}

.quizCard {
width: 80%;
display: flex;
justify-content: center;
flex-direction: column;
margin: 0 auto;
margin-top: 3dvh;
gap: 2dvh;
}

.banner-avatarImg {
width: 100%;
height: 100%;
object-fit: cover;
}

.bannerAvatar-div {
/* width: 18%; */
border-radius: 100%;
/* position: absolute; */
top: 8px;
left: 5%;
height: 70%;
overflow: hidden;
margin-left: 5%;
aspect-ratio: 1/1;
}

.userEventScore {
display: flex;
justify-content: space-around;
width: 83%;
height: 100%;
font-size: 16px;
font-weight: 700;
color: var(--darkerAccentColor);
text-align: center;
overflow-x: auto;
overflow-y: hidden;
margin-left: 5px;
}

.singleEventScore {
height: 100%;
display: flex;
flex-direction: column;
justify-content: space-evenly;
align-items: center;
min-width: 30%;
/* min-width: 40%; */
}

.horizontalBar{
height: 70%;
/* width: 3px; */
min-width: 1%;
background-color: var(--darkerAccentColor);
margin: auto 0;
border-radius: 3px;
}