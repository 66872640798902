.homePageBody{
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  width: 100%;
  font-family: 'Noto Sans Bengali', sans-serif;
}
.scrollableContent {
  flex: 1;
  overflow-y: auto;
  padding-top: 54px; /* Adjust based on your navbar height */
  padding-bottom: 78px; /* Adjust based on your bottombar height */
}
.fixedNavbar {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 430px;
  z-index: 10;
}

.fixedBottombar {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 430px;
  z-index: 10;
}

@media only screen and (min-width: 470px) {
  .fixedNavbar{
    /* width: auto; */
    aspect-ratio: 350 / 50;
  }
  .fixedBottombar {
    /* width: auto; */
    aspect-ratio: 350 / 66;
  }
}

.initialBanner{
  position: absolute;
  width: 100%;
  background: #cccccc66;
  height: 100dvh;
  z-index: 9999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.openingBannerImg{
  width: 90%;
}

.closeBtn{
  font-size: 20px;
  font-weight: 700;
  color: white;
  position: absolute;
  right: 3%;
  top: 25dvh;
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 0px 6px;
}