.accordion-button {
  font-weight: bold;
  padding: 12px;
  background-color: #fff !important;
  color: #232323 !important;
}

.accordion-button:not(.collapsed) {
  background-color: #fff !important;
  color: #5B1674 !important;
  border-bottom: 1px solid #EDEDED;
}