.timerBox {
  height: 5px;
  width: 80%;
  margin: 0 auto;
  background: #ccc;
  border-radius: 30px;
}

.totalTimerTop {
  height: 100%;
  width: 100%;
  background: yellow;
  border-radius: 30px;
}

.progressBox {
  height: 2dvh;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 2rem;
  /* background: #ccc; */
  /* border-radius: 30px;
  border: 2px solid white; */
}

.progressTop {
  height: 100%;
  width: 0%;
  background: #fff;
  border-radius: 30px;
  transition: width .2s;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
}


.timerLinear {
  height: 4dvh;
  width: 30%;
  /* position: absolute; */
  max-height: 24px;
  right: 26%;
  top: 0px;
  /* background: #ccccaa99; */
  margin: 0 auto;
}

.newTimerBox {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  font-weight: 900;
}



.Score-div {
  width: 80%;
  margin: 0 auto;
  min-width: 160px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.tickImg {
  height: 20px;
}

.questionField {
  color: white;
  font-size: 2.7dvh;
  text-align: left;
  font-weight: 600;
  width: 78%;
  height: 22dvh;
  padding-bottom: 10px;
  /* background: #ff000055; */
  display: flex;
  align-items: flex-start;
  margin-top: 15px;
  padding-left: 5%;
}

.questionFieldWrapper {
  position: absolute;
  left: 5%;
  /* box-shadow: rgb(49, 53, 55) 0px 4px 5px; */
  width: 90%;
  height: 100%;
  /* height: 50vh; */
  /* bottom: 0; */
  display: flex;
  align-items: center;
  background: #F67A3E;
  box-shadow: -40px 20px 20px #be511d inset;
  border-radius: 30px;
}

.questionFieldOuter {
  position: absolute;
  /* box-shadow: rgb(49, 53, 55) 0px 4px 5px; */
  width: 75%;
  height: 53dvh;
  bottom: 0;
  display: flex;
  /* border: 2px solid whitesmoke; */
  border-left: none;
  align-items: center;


}

.questionBox {
  width: 100%;
  margin: 0 auto;
  /* background: url('../../../assets/img/v2/gameplayBox3D.png'); */
  background-size: cover;
  padding-bottom: 10px;
  border-radius: 10px;
  min-height: 450px;
  position: absolute;
  bottom: 12dvh;
}

.optionsWrapper {
  width: 42%;
  margin: 0 auto;
  position: absolute;
  right: 1%;
  bottom: -5dvh;
  height: 50dvh;
}

.optionsField {
  display: flex;
  flex-direction: column;
  gap: 1dvh;
  justify-content: flex-start;
}

.singleOptions {
  font-size: 2dvh;
  font-weight: 500;
  text-align: center;
  /* border: 2px solid #ccc; */
  border: 1px outset #ebebeb;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #000;
  background-color: #ffffff66;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(10px);
  min-height: 12dvh;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
  border-radius: 15px;
  filter: drop-shadow(-5px -2px 20px #afafaf);
}

.bg_right {
  background-color: #029602;
}

.bg_wrong {
  background-color: #972727;
}

.op{
  max-width: 90%;
}

.ramadanBoxList:last-child{
  border-bottom-right-radius: 100px;
}