:root {
  --fontColor: #fff;
  --slotsWidth: 44px;
  --tilesWidth: 42px;
  --tilesFront: 40px;
  --tilesShade: 42px;
}
@font-face {
  font-family: 'ContinuumBold';
  src: url('./contb.ttf') format('truetype');
}
.tileFront {
  background-image: url('./front.jpg');
  background-size: cover;
  overflow: hidden;
  border-radius: 5px;
  height: var(--tilesFront);
  width: var(--tilesFront);
  pointer-events: none;
}



.slot {
  position: absolute;
  width: var(--slotsWidth);
  height: var(--slotsWidth);
}

.tile {
  translate: none;
  rotate: none;
  scale: none;
  text-align: center;
  line-height: 48px;
  font-size: 40px;
  color: #430676;
  transform: translate(0px, 0px);
  left: 1px;
  top: 52px;
  height: var(--tilesWidth);
  position: absolute;
  width: var(--tilesWidth);
  justify-content: center;
  display: flex;
  align-items: center;
  font-family: ContinuumBold;
}

.tileShade {
  translate: none;
  rotate: none;
  scale: none;
  opacity: 0.8;
  transform: translate(0px, 2px) scale(1.1, 1.1);
  /* background-image: url('./tileShade.png'); */
  /* background-color: #FF7B33; */
  background-size: cover;
  height: var(--tilesWidth);
  width: var(--tilesWidth);
  pointer-events: none;
  position: absolute;
  z-index: -1;
}

.timeTxt{
  bottom: 30%;
  left: 35%;
}
@media screen and (min-width: 800px) {
  .timeTxt{
    top: 200px;
    left: 715px;
  }
}

.slotAndTiles{
  width: 94%;
  height: 212px;
  top: 3%;
  display: flex;
  align-items: center;
  position: relative;
  /* border-radius: 16px; */
  margin: 0% auto;
  /* border: 2px outset var(--darkerAccentColor); */
  /* background: #ffffff22; */
  /* background: url('./shape.png'); */
  background-size: 100% 100%;
}
.slot{
  width: 44px;
  height: 44px;
}
.timeTxt{
  bottom: 30%;
  left: 35%;
}

.correctHead{
  font-size: 15px;
  font-weight: 600;
  height: 15px;
  /* background: #5B1674; */
  /* width: 100%; */
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ContinuumBold;
}
.correctN{
  font-family: ContinuumBold;
}


.single-chart {
  width: 100%;
  justify-content: space-around ;
  color: #fff;
}

.circular-chart {
  display: block;
  margin: 0px auto;
  max-width: 80%;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}
.percentage {
  fill: #fff;
  font-family: ContinuumBold,sans-serif;
  font-size: 0.5em;
  font-weight: 800;
  text-anchor: middle;
}

.timer-container {
  width: 95%;
  height: 16px;
  background-color: #eee;
  border-radius: 10px;
  overflow: hidden;
  margin: 5% auto;
}

.timer-bar {
  height: 100%;
  width: 0;
  background: linear-gradient(to right, #4caf50, #cddc39);
  border-radius: 10px;
  transition: width 0.1s linear;
}