
  .percentage {
    fill: #fff;
    font-family: 'Noto Sans Bengali', sans-serif;
    font-size: 0.5em;
    font-weight: 800;
    text-anchor: middle;
  }
  
  .timerContainer {
    width: 95%;
    height: 14px;
    background-color: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    margin: 5% auto;
  }
  
  .timerBar {
    height: 100%;
    width: 0;
    background: linear-gradient(to right, #4caf50, #cddc39);
    border-radius: 10px;
    transition: width 0.1s linear;
  }

  .quiz_navbar{
    width: 100%;
    padding: 0% 5%;
    display: flex;
    justify-content: space-between;
    margin: 0px auto;
  }
  .backBtn{   
    cursor: pointer;
    font-size: 34px;
    color: #5B1674;
}
  
 .deleteBtn{
  color: #fff;
  border: 1px solid #fff;
  border-radius: 22px;
  padding: 5px 30px;
  cursor: pointer;
 }
 .rePosition{
  color: #fff;
  border: 1px solid #fff;
  border-radius: 22px;
  padding: 5px 10px;
  cursor: pointer;
 }
 .submitBtn{
  color: #fff;
  border: 1px solid #fff;
  border-radius: 22px;
  padding: 5px 30px;
  cursor: pointer;
 }

 .infoContainer{
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #FA686800;
  padding: 16px;
  color: #2E2E2E;
 }