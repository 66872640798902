.container {
  padding: 16px;
}

.linkBox {
  background: white;
  border-radius: 8px;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

.link {
  color: #361B83;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.copyButton {
  background: transparent;
  border: 1px solid #361B83;
  color: #361B83;
  padding: 4px 6px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 12px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

