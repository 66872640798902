.popUpMenu{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popUpMenuContainer{
    width: 100%;
    aspect-ratio: 529/563;
    position: relative;
    
}

.bgImg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.homeImg{
    position: absolute;
    bottom: 8%;
    left: 30%;
    width: 40%;
}

.leaderboardImg{
    position: absolute;
    top: 42%;
    left: 14%;
    width: 68%;
}

.resumeImg{
    position: absolute;
    top: 24%;
    left: 14%;
    width: 68%;
}

.closeBtn{
    position: absolute;
    top: 9%;
    right: 8.5%;
    width: 13%;
    aspect-ratio: 1 / 1;
    background-color: #f000;
}