 
  .gameContainer,
  .bee-container,
  .rightWordsList {
    border-radius: 10px;
  }
  
  .bgContainer{
    margin: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: absolute;
      left: 0;
    top: 0;
    z-index: -1;
  }
  .bgImage{
    margin: 0;
    height: 100%;
    width: 100%;
  
  }
  
  .gameContainer {
    height: 100%;
    width: 100%;
    max-width: 650px;
    /* background: #cccccc66; */
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto;
    padding: 10px;
    flex-wrap: wrap;
  }
  
  .resultContainer,.playContainer {
    width: 300px;
    height: 354px;
    margin: 0 auto;
  }
  
  .headingsContainer {
    max-width: 650px;
    width: 95%;
    height: 30px;
    cursor: pointer;
    margin: 0 auto;
    font-size: 1em;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 3px solid #002961;
    
    /* margin-top: 1em; */
  }
  
  .bee-container {
    width: 300px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #5d9faa77; */
  }
  
  .bee-container .bee-hive {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .bee-container .bee-hive .hive-cell {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    /* cursor: pointer; */
    height: 75px;
    width: 75px;
    background: #cccccc66;
    /* background: #ffffff; */
    -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
  }
  
  .bee-container .bee-hive .hive-cell.center {
    /* background: #f7da21; */
    background: #9e4be3;
    color: #fff;
  }
  
  .bee-container .bee-hive .hive-cell.upperLeft {
    transform: translate(-80%, -48%);
  }
  
  .bee-container .bee-hive .hive-cell.top {
    transform: translate(0%, -100%);
  }
  
  .bee-container .bee-hive .hive-cell.upperRight {
    transform: translate(80%, -48%);
  }
  
  .bee-container .bee-hive .hive-cell.bottomLeft {
    transform: translate(-80%, 52%);
  }
  
  .bee-container .bee-hive .hive-cell.bottom {
    transform: translate(0%, 100%);
  }
  
  .bee-container .bee-hive .hive-cell.bottomRight {
    transform: translate(80%, 52%);
  }
  
  .bee-container .bee-hive .hive-cell p {
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
    padding: 10px;
    cursor: pointer;
    user-select: none;
  }
  

  .inputField {
    font-size: 20px;
    margin: 0 auto;
    width: 292px;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    /* border-radius: 5px; */
    border: 0px solid #ccc;
    outline: 0px solid #65c1e7;
    background: none;
    caret-color: red;
    color: #fff;
  }
  
  .operationBtns {
    padding: 5px;
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    position: absolute;
    bottom: -12px;
  }
  
  .singleBtn {
    padding: 10px;
    border-radius: 37px;
    border-color: #8b483c6b;
    cursor: pointer;
  }
  
  .rightWordsList{
    width: 280px;
    height: 213px;
    /* background-color: #ffffff; */
    /* background-color: #cccccc66; */
    /* color: #ab8686; */
    padding: 10px;
    overflow-x: hidden;
    z-index: 1;
    overflow-y: auto;
  }
  
  
  /* tooltip */
  .toolTipText {
    display: none;
    /* width: 120px; */
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 15px 5px 15px;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: -22px;
    left: 90px;
  }
  
  .deleteBtn,
  .submitBtn,
  .clearBtn,
  .endBtn,.reloadBtn {
    display: none;
  }
  
  
  /* .submitBtn,.deleteBtn {
    font-weight: bolder;
    padding-left: 20px;
    padding-right: 20px;
    background: #ffffff;
  } */
  
  .reloadBtn{
  
  }
  
  .endBtn {
    font-weight: bolder;
    padding-left: 35px;
    padding-right: 35px;
    background: #e43011;
    box-shadow: -1px 1px 8px #e43011;
    color: #fff;
  }
  
  .clearBtn {
    font-weight: bolder;
    padding-left: 15px;
    padding-right: 15px;
    background: #e5e765;
  }
  
  /* .deleteBtn {
    font-weight: bolder;
    padding-left: 15px;
    padding-right: 15px;
    background: #ffd12e;
  } */
  
  .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }
  
  .form-label {
    margin-bottom: 0.5rem;
  }
  
  
  .btn-primary {
    color: rgb(232, 230, 227);
    background-color: rgb(2, 80, 196);
    border-color: rgb(1, 71, 174);
  }
  
  
  /* pop up */
  .popup {
    display: flex;
    align-items: flex-start;
    position: absolute;
    width: 90%;
    max-width: 300px;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #ccc;
    box-shadow: 0 4px 24px #e6e6e6;
  }
  
  .popup-content {
    display: flex;
    max-width: 90%;
    flex-wrap: wrap;
    flex-grow: 9;
    padding: 12px;
  }
  
  .popup-heading {
    margin: 0 auto 10px auto;
    font-size: 1.4em;
    flex-basis: 100%
  }
  
  .popup-content-text {
    width: 100%;
    font-size: .8em;
    color: #333;
  }
  
  .popup-content-text ul li {
    margin-left: 24px;
  }
  
  .return-popup-header {
    margin-top: 35px
  }
  
  #popup-exit {
    display: flex;
    width: 12px;
    min-width: 12px;
    height: 12px;
    cursor: pointer;
    flex-grow: 1;
    flex-basis: 10%;
    position: relative;
    top: 15px;
    right: 15px;
    background-size: contain;
    outline: 0;
    transition: transform 0.2s
  }
  
  
  #progress-container {
    max-width: 600px;
    width: 95%;
    height: 30px;
    cursor: pointer;
    margin: 0 auto;
    font-size: 1em;
    display: flex;
    align-items: center;
    /* border: 1px solid #ccc; */
    padding: 5px 20px;
    margin-bottom: 10px;
    border-radius: 10px;
    /* background-color: #65c1e7; */
  }
  
  #progress-info {
    width: 37%
  }
  
  #progress-next {
    font-size: .7em;
    font-weight: 300
  }
  
  #progress-bar {
    width: 63%;
    position: relative;
    top: -2px
  }
  
  #progress-dots {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: .7em
  }
  
  .progress-dot {
    display: flex;
    flex-grow: 1
  }
  
  .progress-complete,
  .progress-incomplete {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    transform: translate(-50%, 50%)
  }
  
  #progress-current {
    display: flex;
    width: 24px;
    height: 24px;
    position: absolute;
    align-items: center;
    vertical-align: middle;
    line-height: 100%;
    /* left: calc(0 * 100% / 9); */
    left: 0%;
    transform: translate(-50%, -50%);
    transition: left 0.25s;
    /* border: 2px solid #ffd12e; */
    color: #000;
    /* background-color: #ffd12e; */
    background-size: cover;
  }
  
  #progress-current-score {
    width: 100%;
    text-align: center;
    font-size: .7em
  }
  
  .progress-complete {
    background-color: #ffd12e;
  }
  
  .progress-incomplete {
    background-color: #e6e6e6;
  }
  
  .progress-square {
    border-radius: 0
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  .blur {
    filter: blur(3px);
  }
  
  .hidden {
    display: none;
  }
  
  
  
  /* @media only screen and (min-width: 1380px) {
    .headingsContainer{
      margin-top: 1em;
    }
  } 
  /* @media only screen and (min-width: 768px) {
    .headingsContainer{
      margin-top: 10em;
    }
  }  */
  
  @media only screen and (max-width: 1400px) {
      .bgImage{
        transform: translate(-10px, 50px) scale(1.3, 1.6);
    }
  }
  @media only screen and (min-width: 1400px) {
      .bgImage{
        transform: translate(0px, 0px) scale(1, 1);
    }
    .headingsContainer{
      margin-top: 4em;
    }
  }
  
  
  .playBtn,.submitBtn,.reloadBtn,.deleteBtn,.submitScoreBtn{
    box-shadow: -2px 3px 8px #0c0b5b;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .cellCenter{
    /* background-size: cover !important; */
    background-position: 0px !important;
    color: #003366 !important;
  }
  
  
  
  .normalCell{
    /* background-size: cover !important; */
    background-position: 0px !important;
    color: #fff !important;
  }
  
  
  .wordBoard{
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 227px;
    left: 0;
    border-radius: 10px;
    box-shadow: -6px 3px 12px #003366;
  }