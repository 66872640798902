.playerStatusCardContainer {
  width: 100%;
  /* padding: 0px 16px; */
}

.playerStatusCard {
  background: #fff;
  border-radius: 16px;
  color: #2E2E2E;
  width: 100%;
  box-shadow: 0px 2px 4px -1px #0000000F;
  box-shadow: 0px 4px 6px -1px #0000001A;
  padding: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.userInfo {
  display: flex;
  align-items: center;
  gap: 12px;
}

.avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid white
}

.nameContainer h3 {
  font-size: 18px;
  margin: 0;
}

.nameContainer p {
  font-size: 10px;
  margin: 4px 0 0 0;
  opacity: 1;
  font-family: 'Noto Sans Bengali', sans-serif;
  font-weight: 400;

}

.subscriptionBadge {
  /* background: rgba(255, 255, 255, 0.2); */
  background: #1AA36D;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 12px;
  color: #fff;
}

.statsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #0000001A;
  /* background: linear-gradient(to right, #4a1e9e, #7d42f5); */
  padding: 15px 5px;
  border-radius: 8px;
  margin-top: 20px;
  width: 100%;
  box-shadow: 0px 2px 3px 0px #00000033;
}

.statItem {
  text-align: center;
  flex: 1;
}

.statItem h4 {
  color: #2E2E2E;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
}

.statValue {
  color: #2E2E2E;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}

.divider {
  width: 1px;
  height: 40px;
  background-color: #2E2E2E;
  margin: 0 15px;
  opacity: .2;
}

.unsubscribed {
  background-color: #dc3545 !important;
  /* or any other red color of your choice */
}