#mobileNumber {
  width: 80%;
  font-size: 16px;
  height: 30px;
  border-radius: 5px;
  border: none;
}
#mobileNumber:focus{
  border: none;
  outline: none;
}
.inputSpan{
  padding: 8px 3px;
    border: 1px solid #ccc;
    border-radius: 5px;
  width: 90%;
}

.numberSubmit {
  width: 50%;
  border-radius: 20px;
  background: #6c4e9d;
  color: white;
  margin: 0 auto;
}

/* .modal-header {
  background: #6c4e9d;
  color: white;
  border-top-right-radius: 17px;
  border-top-left-radius: 17px;
  text-align: center;
}

.modalContent {
  border-radius: 20px;
  width: 85%;
  margin: 0 auto;
}

.modal-title {
  font-size: 14px;
  text-align: center;
  width: 100%;
}

.modal-dialog {
  max-width: 425px;
} */

.fixedDivLogin{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  background: #ffffffdd;
  border-radius: 20px;
  border: 1px solid #ccc;
  filter: drop-shadow(0px 2px 10px #00000066);
}
.backdropOpacity{
  opacity: 0 !important;
}
.campaign-login-modal-dialog {
  max-width: 425px;
  position: relative;
  top: 40dvh;
}
.errorOutline{
  outline: 2px solid red;
}

.inputFieldMobile{
  -webkit-user-select: text;
}