.container {
  border-radius: 15px;
  padding: 24px 16px 32px 16px;
  text-align: center;
}

.header {
  color: white;
  padding: 30px;
  border-radius: 12px;
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  box-shadow: 0px 2px 4px -1px #0000000f;
  box-shadow: 0px 4px 6px -1px #0000001a;

}
.trophy {
  width: 60px;
  aspect-ratio: 1/1;
}
.score {
  font-size: 16px;
  padding: 8px;
  border-radius: 8px;
  background: #ffffff1a;
  width: 100%;
}

.stats {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.stats div {
  text-align: center;
  flex: 1;
}

.stats div:not(:last-child) {
  border-right: 1px solid #ddd;
}

.button {
  background-color: #5B1674;
  color: white;
  padding: 7px 10px;
  border: none;
  border-radius: 28px;
  margin: 10px 0;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
}

.buttonOutline {
  background-color: transparent;
  color: #5B1674;
  padding: 7px 10px;
  border: 2px solid #5B1674;
  border-radius: 28px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
}

.congratsTitle {
  font-size: 20px;
  font-weight: 700;
}

.congratsText {
  font-size: 14px;
  font-weight: 500;
}

.fireworks{
  position: absolute;
  width: 100%;
  bottom: 0px;
  scale: 0.6;
  display: none;
}