.ppm_navbar {
  height: 54px;
  width: 100%;
  padding: 16px 16px 16px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 9;
  box-shadow: 0px 2px 2px 0px #0000001A;
  margin-bottom: 20px;
  background: #fff;
}
.toggleBtn{
  cursor: pointer;
  height: 28px;
}

.ppm_logo{
  height: 32px;
}