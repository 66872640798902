.bannerPageBody {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 0px 0px 32px 0px;
}

.gameCorner {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.backgroundWrapper {
  width: 100%;
  background-image: url(./img/background.png);
  background-size: cover;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}


.gamesTitle {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  font-family: 'LiAdorNoirrit';
}


.gameItemDetails {
  display: flex;
  align-items: center;
  gap: 16px;
}

.gameItemImg {
  width: 40px;
}

.gamesList {
  display: flex;
  gap: 20px;
  justify-items: start;
  align-items: center;
  flex-wrap: nowrap;
}

.gameItem {
  /* background-color: #6FFAC3; */
  border-radius: 22px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 28px 20px;
  text-align: center;
  width: 142px;
  /* border: 1.5px solid #E6E8EC; */
  cursor: pointer;
  aspect-ratio: 200 / 295;
  background-size: 100% 100%;
  transition: transform 0.3s ease-in-out;
  animation: cardAnimation 2s ease-in-out infinite;
}

@keyframes cardAnimation {
  0% {
    transform: rotateY(0deg) scale(1) skew(0deg, 0deg);
  }
  50% {
    transform: rotateY(5deg) scale(1.02) skew(1deg, -1deg);
  }
  100% {
    transform: rotateY(0deg) scale(1) skew(0deg, 0deg);
  }
}
.gameItem:hover {
  transform: rotateY(0deg) scale(1.05) skew(0deg, 0deg);
}

.gameItemImg {
  width: 52px;
  height: 52px;
  margin-bottom: 10px;
}
.gameItemTitle {
  font-size: 16px;
  font-weight: 700;
  font-family: 'Noto Sans Bengali', sans-serif;
}



.playButton {
  background-color: #5B1674;
  color: white;
  border: none;
  border-radius: 28px;
  padding: 4px 15px;
  cursor: pointer;
  margin-top: 18px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Noto Sans Bengali', sans-serif;
}


.playButton:hover {
  background-color: #4a148c;
}

.infoIcon{
  font-size: 33px;
  color: #ccc;
  position: absolute;
  left: 98px;
  top: 12px;
  padding: 5px;
}